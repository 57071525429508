/**
 * Copyright Compunetix Incorporated 2017-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Output, EventEmitter, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { IUser, ILocalization, Alert, AlertLevel } from "companion";
import { LocalizationService } from "../localization/localization.service";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";
import { LoginFormComponent } from "./login-form.component";

@Component({
  selector: "login-modal",
  templateUrl: "./login-modal.template.html"
})
export class LoginModalComponent {
  /**
   * Localization Data
   */
  get localizationData(): ILocalization {
    if (this.localizationService.myLocalizationData && this.localizationService.myLocalizationData.loginPanel) {
      return this.localizationService.myLocalizationData;
    } else {
      return {
        loginPanel: {
          exitButtonText: "Exit",
          title: "Sign In",
          emailFieldTitle: "E-Mail",
          passwordFieldTitle: "Password",
          usernameFieldTitle: "Email",
          submitButtonText: "SIGN IN",
        },
        errorMessages: { WAIT: "Please wait..." }
      };
    }
  }

  /**
   * callback action
   */
  callback: ActionType;

  /**
   * window exit event trigger
   */
  @Output("exit") exitEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  /**
   * login event trigger
   */
  @Output("login") loginEmitter: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild("loginForm")
  loginForm: LoginFormComponent;

  constructor(public localizationService: LocalizationService, private router: Router) {
    Dispatcher.register(ActionType.OpenLoginModal, this.open.bind(this));
    Dispatcher.register(ActionType.CloseLoginModal, this.close.bind(this));
  }

  /**
   * login event handler
   */
  login(errorMessage?: string) {
    if (errorMessage) {
      Dispatcher.dispatch(ActionType.Alert, {
        alert: new Alert(
          "FAIL_TO_LOGIN",
          errorMessage,
          AlertLevel.warning
        )
      });
      return;
    }
    Dispatcher.dispatch(ActionType.CloseLoginModal);
    if (this.callback != null) {
      Dispatcher.dispatch(this.callback);
    }
    this.loginEmitter.emit(errorMessage);
  }
  /**
   * window exit event handler
   */
  exit(): void {
    this.exitEventEmitter.emit();
  }
  /**
   * open modal
   */
  open(payload: any): void {
    this.loginForm.resetForm();
    $("#loginModal").modal("show");
    this.loginForm.defaultFocus();
    this.callback = payload && payload.callback;
  }

  /**
   * close modal
   */
  close(): void {
    $("#loginModal").modal("hide");
  }
}
