/**
 * Copyright Compunetix Incorporated 2017-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Output, EventEmitter, ViewChild, Input } from "@angular/core";
import {IUser, ILocalization, IUserService, Companion, Browser} from "companion";
import { LocalizationService } from "../localization/localization.service";
import { LoginFormComponent } from "./login-form.component";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";

@Component({
  selector: "login-screen",
  templateUrl: "./login-screen.template.html"
})
export class LoginScreenComponent {
  /**
   * Localization Data
   */
  get localizationData(): ILocalization {
    if (this.localizationService.myLocalizationData && this.localizationService.myLocalizationData.loginPanel) {
      return this.localizationService.myLocalizationData;
    } else {
      return {
        loginPanel: {
          exitButtonText: "Exit",
          title: "Sign In",
          emailFieldTitle: "E-Mail",
          passwordFieldTitle: "Password",
          usernameFieldTitle: "Email",
          submitButtonText: "SIGN IN",
        },
        errorMessages: { WAIT: "Please wait..." }
      };
    }
  }

  /**
   * login event trigger
   */
  @Output("login") loginEmitter: EventEmitter<string> = new EventEmitter<string>();

  /**
   * login form child view
   */
  @ViewChild(LoginFormComponent)
  loginFormComponent: LoginFormComponent;

  /**
   * user service from companion lib
   */
  userService: IUserService = Companion.getUserService();

  /**
   * if login submitted
   */
  submitted: boolean;

  /**
   * flag if it's mobile app
   */
  @Input() isMobileApp: boolean;

  /**
   * component constructor
   */
  constructor(public localizationService: LocalizationService) {
    // nothing needed here
  }
  /**
   * login event handler
   */
  login(errorMessage?: string) {
    this.submitted = true;
    if (errorMessage) {
      console.log("LOG ERROR", errorMessage);
    }
    this.loginEmitter.emit(errorMessage);
  }
  /**
   * update view with displaying error message or reset form
   * @param errorMsg?: string
   */
  updateView(errorMsg?: string) {
    if (this.loginFormComponent) {
      this.loginFormComponent.updateView(errorMsg);
    }
  }

  logout() {
    this.userService.currentUser.isAuthenticated = false;
    Dispatcher.dispatch(ActionType.LogOut);
  }

  /**
   * mouseover event handler on submit button
   */
  onMouseEnter(e: any) {
    let target: HTMLElement = e.target;
    target.style.backgroundColor = this.localizationData.loginPanel.submitButtonColor;
  }

  /**
   * mouseleave event handler on submit button
   */
  onMouseLeave(e: any) {
    let target: HTMLElement = e.target;
    target.style.backgroundColor = this.localizationData.loginPanel.submitButtonColor;
  }
}
